<template>
  <v-card
    flat
    class="mt-2 mb-0 pa-0"
    dense
    color="grey lighten-4"
    text
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        offset-md="1"
      >
        <v-row align="center">
          <v-col cols="auto">
            <span class="subtitle-1" v-text="$t('multiproject.common.weekStartReal')" />
          </v-col>
          <v-col>
            <v-text-field
              :value="value.weekStartReal"
              :prefix="$t('multiproject.common.week')"
              readonly
              solo
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="5"
        offset-md="1"
      >
        <v-row align="center">
          <v-col cols="auto">
            <span class="subtitle-1" v-text="$t('multiproject.common.weekEndPlanned')" />
          </v-col>
          <v-col>
            <v-menu
              v-model="openedEdit"
              :close-on-content-click="false"
              :close-on-click="false"
              :nudge-width="0"
              right
            >
              <template v-slot:activator="{ }">
                <v-text-field
                  :value="value.weekEndPlanned"
                  :prefix="$t('multiproject.common.week')"
                  readonly
                  solo
                  dense
                  hide-details
                  append-outer-icon="mdi-pencil"
                  @click:append-outer.stop="openEdit"
                />
              </template>

              <v-card>
                <v-form @submit.prevent="updateWeekEndPlanned">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="grow">
                        <v-text-field
                          v-model="internalDateEndValue"
                          :label="$t('multiproject.common.weekEndPlanned')"
                          :prefix="$t('multiproject.common.week')"
                          :min="currentRound"
                          type="number"
                          dense
                          prepend-icon="mdi-calendar"
                          outlined
                          hide-details
                          persistent-hint
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          :disabled="internalDateEndValue < currentRound"
                          class="mx-1"
                          fab
                          small
                          color="success"
                          type="submit"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          outlined
                          class="mx-1"
                          fab
                          small
                          color="error"
                          @click="openedEdit = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ProjectDateCard',
  props: {
    value: {
      type: Object,
      required: true,
    },
    currentRound: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    internalDateEndValue: null,
    openedEdit: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler () {
        this.internalDateEndValue = this.value.weekEndPlanned
      },
    },
  },
  methods: {
    openEdit () {
      this.openedEdit = true
      this.internalDateEndValue = this.value.weekEndPlanned
    },
    updateWeekEndPlanned () {
      this.$emit('change-week-end-planned', this.internalDateEndValue)
      this.openedEdit = false
    },
  },
}
</script>
